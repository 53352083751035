<template>
  <div class="row">
    <div class="card card-body">
        <div class="col-12 table-responsive">
            <b-modal id="create" title="" hide-footer>
                <div class="form-group">
                  <h5 for="">العنوان</h5>
                  <input type="text"
                    class="form-control" v-model="create.title" placeholder="اكتب هنا...">
                </div>
                <div class="form-group">
                  <h5 for="">النوع</h5>
                  <select class="form-control" v-model="create.type">
                    <option value="video">فيديو يوتيوب</option>
                    <option value="pdf">كتاب PDF</option>
                    <option value="image">صورة</option>
                  </select>
                </div>
                <div class="form-group" v-if="create.type == 'video'">
                  <h5 for="">رابط الفيديو</h5>
                  <input type="text"
                    class="form-control" v-model="create.source" placeholder="https://youtube.com/watch?v=....">
                </div>
                <div class="form-group" v-if="create.type != 'video'">
                  <h5 for="">اختيار الملف</h5>
                  <input type="file"
                    class="form-control" @change="listenFile()" id="file">
                </div>
                <div class="form-group" v-if="create.type == 'pdf'">
                  <h5 for="">غلاف الكتاب</h5>
                  <input type="file"
                    class="form-control" @change="listenFile2()" id="file2">
                </div>
                <div class="form-group">
                  <h5 for="">تفاصيل (اختياري)</h5>
                  <textarea class="form-control" v-model="create.details" rows="3"></textarea>
                </div>
                <div id="progress-wrp">
                    <div class="progress-bar"></div>
                    <div class="status">0%</div>
                </div>
                <div class="col-12 text-center g">
                    <button class="btn btn-primary" @click="createNow()">
                        اضافة الآن
                    </button>
                </div>
            </b-modal>
            <h5>
                التوعية <button v-b-modal.create class="btn btn-sm btn-success g" style="border-radius: 50%; padding: 5px">
                    <i class="fa fa-plus"></i>
                </button>
            </h5>
            <table class="table table-hover table-bordered table-sm">
                <thead>
                    <th>
                        العنوان
                    </th>
                    <th>
                        النوع
                    </th>
                    <th>
                        المصدر
                    </th>
                    <th>
                        تاريخ الاضافة
                    </th>
                    <th>
                        خيارات
                    </th>
                </thead>
                <tbody>
                    <tr v-for="item in list" :key="item._id">
                        <td>
                            {{ item.title }}
                        </td>
                        <td>
                            {{ item.type.replace("video", "فيديو").replace("pdf", 'كتاب').replace("image", "صورة") }}
                        </td>
                        <td>
                            <a :href="item.source" target="_blank">
                                <i class="fa fa-external-link"></i>
                                عرض
                            </a>
                        </td>
                        <td>
                            {{ item.date.split("T")[0] }}
                        </td>
                        <td>
                            <button class="btn btn-sm btn-outline-danger fa fa-trash" @click="deleteItem(item._id)"></button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
  </div>
</template>

<script>
import {  VBModal, BModal, BDropdown, BDropdownItem } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
export default {
  components: {
    BDropdown,
    BDropdownItem,
    BModal
  },
  directives: {
    Ripple,
    "b-modal": VBModal,
  },
    data(){
        return {
            user: JSON.parse(localStorage.getItem('user')),
            list: [],
            current: {},
            create: {type: 'video'}
        }
    },
    created(){
        var g = this;
        g.gett()
        if(!checkPer('awareness')){
            this.$router.push('/pers')
            return;
        }
    },
    methods: {
        gett(){
            var g = this;
            $.post(api + '/admin/web/awareness/list', {
                jwt: this.user.jwt
            }).then(function(r){
                g.list = r.response
            })
        },
        deleteItem(id){
            if(confirm("متأكد من الحذف؟")){
                var g = this;
                $.post(api + '/admin/web/awareness/delete', {
                    jwt: this.user.jwt,
                    id: id
                }).then(function(r){
                    g.gett()
                })
            }
        },
        createNow(){
            var g = this;
            $.post(api + '/admin/web/awareness/create', {
                jwt: this.user.jwt,
                create: this.create
            }).then(function(r){
                $("#create___BV_modal_header_ > button").click()
                g.gett()
            })
        },
        listenFile(){
            var g = this;
            var Upload = function (file) {
            this.file = file;
            };

            Upload.prototype.getType = function () {
            return this.file.type;
            };
            Upload.prototype.getSize = function () {
            return this.file.size;
            };
            Upload.prototype.getName = function () {
            return this.file.name;
            };
            Upload.prototype.doUpload = function () {
            var that = this;
            var formData = new FormData();

            // add assoc key values, this will be posts values
            formData.append("file", this.file, this.getName());

            $.ajax({
                type: "POST",
                url: api + '/user/web/upload',
                xhr: function () {
                var myXhr = $.ajaxSettings.xhr();
                if (myXhr.upload) {
                    myXhr.upload.addEventListener(
                    "progress",
                    that.progressHandling,
                    false
                    );
                }
                return myXhr;
                },
                success: function (data) {
                // your callback here
                g.users = [];
                $("#progress-wrp").fadeOut("slow");
                g.create.source = data.response
                },
                error: function (error) {
                // handle error
                alert("حدث خطأ");
                },
                async: true,
                data: formData,
                cache: false,
                contentType: false,
                processData: false,
                timeout: 60000,
            });
            };

            Upload.prototype.progressHandling = function (event) {
            var percent = 0;
            var position = event.loaded || event.position;
            var total = event.total;
            var progress_bar_id = "#progress-wrp";
            if (event.lengthComputable) {
                percent = Math.ceil((position / total) * 100);
            }
            // update progressbars classes so it fits your code
            $(progress_bar_id + " .progress-bar").css("width", +percent + "%");
            $(progress_bar_id + " .status").text(percent + "%");
            };
            var file = $("#file")[0].files[0];
            $("#progress-wrp").fadeIn("slow");
            var upload = new Upload(file);
            upload.doUpload();
        },

        listenFile2(){
            var g = this;
            var Upload = function (file) {
            this.file = file;
            };

            Upload.prototype.getType = function () {
            return this.file.type;
            };
            Upload.prototype.getSize = function () {
            return this.file.size;
            };
            Upload.prototype.getName = function () {
            return this.file.name;
            };
            Upload.prototype.doUpload = function () {
            var that = this;
            var formData = new FormData();

            // add assoc key values, this will be posts values
            formData.append("file", this.file, this.getName());

            $.ajax({
                type: "POST",
                url: api + '/user/web/upload',
                xhr: function () {
                var myXhr = $.ajaxSettings.xhr();
                if (myXhr.upload) {
                    myXhr.upload.addEventListener(
                    "progress",
                    that.progressHandling,
                    false
                    );
                }
                return myXhr;
                },
                success: function (data) {
                // your callback here
                g.users = [];
                $("#progress-wrp").fadeOut("slow");
                g.create.cover_source = data.response
                },
                error: function (error) {
                // handle error
                alert("حدث خطأ");
                },
                async: true,
                data: formData,
                cache: false,
                contentType: false,
                processData: false,
                timeout: 60000,
            });
            };

            Upload.prototype.progressHandling = function (event) {
            var percent = 0;
            var position = event.loaded || event.position;
            var total = event.total;
            var progress_bar_id = "#progress-wrp";
            if (event.lengthComputable) {
                percent = Math.ceil((position / total) * 100);
            }
            // update progressbars classes so it fits your code
            $(progress_bar_id + " .progress-bar").css("width", +percent + "%");
            $(progress_bar_id + " .status").text(percent + "%");
            };
            var file = $("#file2")[0].files[0];
            $("#progress-wrp").fadeIn("slow");
            var upload = new Upload(file);
            upload.doUpload();
        }
    }
}
</script>

<style scoped>
#progress-wrp {
  border: 1px solid #0099cc;
  padding: 1px;
  position: relative;
  height: 30px;
  border-radius: 3px;
  margin: 10px;
  text-align: left;
  background: #fff;
  box-shadow: inset 1px 3px 6px rgba(0, 0, 0, 0.12);
  display: none;
}

#progress-wrp .progress-bar {
  height: 100%;
  border-radius: 3px;
  background-color: greenyellow;
  width: 0;
  box-shadow: inset 1px 1px 10px rgba(0, 0, 0, 0.11);
}

#progress-wrp .status {
  top: 3px;
  left: 50%;
  position: absolute;
  display: inline-block;
  color: #000000;
}
</style>